<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 h-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
            class="w-100 h-100"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-0 text-center"
          >
            <b-img
              fluid
              :src="logoImg"
            />
          </b-card-title>
          <b-card-text class="mb-1">
            <div class="d-flex flex-column text-center title-container">
              <span>Welcome to CMS</span>
              <a class="text-info">www.akarahospitality.com</a>
            </div>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="createPasswordForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Create Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <!-- <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend> -->
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      :type="password1FieldType"
                      placeholder="Create Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="confirm-password">Re-enter your password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="confirm password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <!-- <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend> -->
                    <b-form-input
                      id="confirm-password"
                      v-model="confirmPassword"
                      :state="errors.length > 0 ? false:null"
                      name="confirm-password"
                      :type="password2FieldType"
                      placeholder="Re-enter your password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <div class="forgot-password">
                <b-link 
                  class="text-info"
                  :to="{ name:'auth-forgot-password' }"
                >
                  <small>Forgot Password?</small>
                </b-link>
              </div> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                pill
                :disabled="isDisabledButton"
                @click="validationForm"
              >
                Register
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-1 text-secondary">
            <span>If you have any issue. Please contact 097-006-0592</span>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue'
import { required, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import router from '@/router'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from './useAuth'
import authStoreModule from './authStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      confirmPassword: '',
      sideImg: require('@/assets/images/pages/login-v3.svg'),
      logoImg: require('@/assets/images/logo/logo.svg'),
      required,
      confirmed,
      password1FieldType: 'password',
      password2FieldType: 'password',
      loading: false,
    }
  },
  computed: {
    isDisabledButton() {
      return !this.confirmPassword || !this.password || this.loading
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    localStorage.removeItem('userData')
  },
  methods: {   
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    }, 
    validationForm() {
      this.$refs.createPasswordForm.validate().then(async (success) => {
        if (!success) return

        this.loading = true
        const { status } = await this.setPassword({ id: router.currentRoute.query.id, password: this.password });
        this.loading = false
        if (status !== 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error set password.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return;
        }
        
        this.$router
          .push({ name: 'auth-login' })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password has been updated.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
      })
    },
  },
  setup() {
    const AUTH_APP_STORE_MODULE_NAME = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP_STORE_MODULE_NAME)) store.registerModule(AUTH_APP_STORE_MODULE_NAME, authStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTH_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      setPassword,
    } = useAuth()
    
    return {
      toast,
      setPassword,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.forgot-password {
  margin-top: -5px;
  margin-bottom: 30px;
}
.text-info {
  color: #0E76A8 !important;
}
.title-container {
  & span {
    font-size: 18px;
  }
}
</style>
